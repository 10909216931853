<template>
  <div v-if="loaded">
    <div class="container h-100 content">
      <div class="d-flex justify-content-center h-100">
        <div class="login block link-block">
          <div>
            <h3>{{ linkData.title }}</h3>

            <div v-if="isError" class="alert-box" :class="error.class">
              <div class="title">
                {{ error.title }}
              </div>

              <p v-html="error.desc"></p>
            </div>

            <div v-else>
              <p v-html="linkData.description"></p>

              <div v-if="attendanceConfirmed">
                <b-alert variant="success" show>
                  <strong>Attendance Confirmed</strong>
                  <div>
                    We have received your confirmation. Please look out for an
                    e-mail in your inbox shortly before the attendance date to
                    advise of the next steps. Note: we cannot provide you with
                    an exact time on the day.
                  </div>
                </b-alert>
              </div>

              <div v-if="!attendanceConfirmed">
                <form @submit.prevent="confirmAttendance">
                  <div
                    class="row"
                    style="padding-top: 0px; padding-bottom: 0px;"
                  >
                    <div class="col-md-12" style="margin-top: 20px;">
                      <b-form-input
                        id="customer-name"
                        class="mt-3 mb-3"
                        placeholder="Please provide your full name"
                        v-model="customer.name"
                        type="text"
                        size="m"
                      ></b-form-input>

                      <b-form-input
                        id="customer-property"
                        class="mt-3 mb-3"
                        placeholder="Please provide your full address"
                        v-model="customer.property"
                        type="text"
                        size="m"
                      ></b-form-input>

                      <b-form-input
                        id="customer-email"
                        class="mt-3 mb-3"
                        placeholder="Please provide your email address"
                        v-model="customer.email"
                        type="email"
                        size="m"
                      ></b-form-input>

                      <b-form-input
                        id="customer-phone"
                        class="mt-3 mb-3"
                        placeholder="Please provide your phone number"
                        v-model="customer.phone"
                        type="tel"
                        size="m"
                      ></b-form-input>

                      <strong>Terms and Conditions</strong>
                      <div v-html="linkData.terms" class="termsx"></div>

                      <b-form-checkbox
                        id="terms"
                        v-model="customer.termsAccepted"
                        name="terms-and-conditions"
                        class="mt-3 mb-2 text-left"
                      >
                        I agree to the terms and conditions and understand this
                        appointment cannot be cancelled
                      </b-form-checkbox>
                    </div>
                  </div>

                  <div class="continue-button">
                    <b-button
                      type="submit"
                      variant="outline-info"
                      v-if="!appointmentLoading"
                      >Continue</b-button
                    >
                  </div>
                  <div v-if="appointmentLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </form>
              </div>
              <div class="footer-info">
                <p>
                  If you need any assistance please contact the customer
                  services team via our
                  <a href="https://dataenergy.co.uk/residents" target="_blank"
                    >Residents Hub</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faAlignLeft } from "@fortawesome/free-solid-svg-icons";

library.add(fas, faSpinner, faAlignLeft);

export default {
  name: "AppointmentAcceptance",
  components: {},
  props: {
    linkKey: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loaded: false,
      attendanceConfirmed: false,
      isError: false,
      error: {
        title: "",
        desc: "",
        class: ""
      },
      customer: {
        name: "",
        property: "",
        email: "",
        phone: "",
        termsAccepted: false
      },
      appointmentLoading: false
    };
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    linkData() {
      return this.$store.state.appointment.linkData;
    }
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  methods: {
    setError(title, desc, className) {
      this.error.title = title;
      this.error.desc = desc;
      this.error.class = className;
      this.isError = true;
    },
    clearError() {
      this.error.title = "";
      this.error.desc = "";
      this.error.class = "";
      this.isError = false;
    },
    fetchData() {
      this.clearError();
      const title = "Please Wait";
      const subtitle = "Getting appointment acceptance details";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      this.$store
        .dispatch("getAcceptanceStatus", { linkKey: this.linkKey })
        .then(response => {
          if (
            response.is_valid === false &&
            response.error_response === "Link expired"
          ) {
            this.setError(
              "Link expired",
              "Sorry, the attendance link has expired. Please look out for a new email soon\n" +
                "with a new date to confirm",
              "warning"
            );
          }
          if (
            response.is_valid === false &&
            response.error_response === "Maximum acceptances exceeded"
          ) {
            this.setError(
              "Maximum appointments exceeded",
              "Sorry we have exceeded the maximum amount for appointments for this day,\n" +
                "please look out for another e-mail regarding another date soon.",
              "warning"
            );
          }
          this.appointmentData = response;
        })
        .catch(error => {
          if (error === "Invalid link key") {
            this.setError(
              "Invalid link key",
              "Sorry the appointment is invalid",
              "warning"
            );
          }
        })
        .finally(() => {
          this.loaded = true;
          this.$store.dispatch("loaderCancel");
        });
    },
    confirmAttendance() {
      this.appointmentLoading = true;
      let errorReason = null;

      if (!this.customer.name) {
        errorReason = "Please enter a name";
      }

      if (!this.customer.property) {
        errorReason = "Please enter a property";
      }

      if (!this.customer.email) {
        errorReason = "Please enter a valid email";
      }

      if (!this.customer.phone) {
        errorReason = "Please enter a valid phone number";
      }

      if (!this.customer.termsAccepted) {
        errorReason = "Please accept the terms and conditions to continue";
      }

      if (errorReason) {
        const title = "Appointment Error";
        const subtitle = errorReason;
        const samePage = true;
        this.$store.dispatch("pushError", { title, subtitle, samePage });
        this.appointmentLoading = false;
        return;
      }

      const data = {
        name: this.customer.name,
        property: this.customer.property,
        email: this.customer.email,
        phone: this.customer.phone,
        termsAccepted: this.customer.termsAccepted,
        linkKey: this.linkKey
      };

      this.$store
        .dispatch("confirmAttendance", { data })
        .then(() => {
          this.attendanceConfirmed = true;
        })
        .catch(err => {
          const title = "Attendance Confirmation Error";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.appointmentLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.link-block {
  width: 650px;
  margin-top: 30px;
}

h2 {
  font-size: 1.6rem !important;
  text-align: left !important;
  margin-bottom: 10px !important;
  padding-left: 10px;
  margin-top: 20px;
}

p {
  color: #777;
  font-size: 0.9rem;
}

.payment-button {
  text-align: right;
  padding-top: 15px;
}

.link-block {
  width: 650px;
  margin-top: 30px;
}

.alert-box {
  padding: 20px;
  color: white;
  margin-bottom: 15px;
  margin-top: 20px;

  .title {
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 1.2rem;
  }

  p {
    color: white;
  }

  &.danger {
    background-color: #ff7b71;
  }

  &.warning {
    background-color: #ff9800;
  }

  &.success {
    background-color: #83c45d;
  }
}

.paid-block {
  font-size: 1rem;
  color: #888;

  &.is-paid {
    font-size: 1.2rem;
    font-weight: bold;
    color: #444;
  }
}

h4 {
  margin-top: 25px;
}

.footer-info {
  margin-top: 40px;

  p {
    font-size: 0.8rem;
    color: #888;
    margin-bottom: 0px;
  }
}

.account-block {
  .title {
    font-size: 0.8rem;
    margin-top: 10px;
  }

  .item {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.debt-options {
  text-align: left;
  margin-top: 10px;
  font-size: 0.9rem;

  span {
    font-weight: bold;
  }

  .button-option {
    margin-top: 10px;
  }
}

.termsx {
  overflow-y: scroll;
  height: 150px;
  text-align: left;
  font-size: 0.8rem;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
}
</style>
